import React from 'react';
import {bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import * as Actions from '../redux/actions';
import { useFuse } from "../components/useFuse";
import { Checkbox } from 'react-materialize';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import M from 'materialize-css';


class QuoteGeneratorModal extends React.Component {
  constructor() {
    super();
    this.state = {
      saving: false,
      productSearchText: null,
      showProductSearch: true,
      tempInput: {}, // {'body': {value: 'hello', status: 'saving'}, 'quote_lines.1.model': {value: 'r-123', status: 'editing'}}
      errorFields: [],
      addProductMode: false, // true on mouseover quoteable_product
      tempEmail: '',
      confirmDelete: false,
      // showRecipientSelect: true // for debugging
    }
  }

  componentDidMount() {
    if (!this.props.quotableProducts || !this.props.quote) {
      this.props.newQuote(this.props.authToken, {
        customer_id: this.props.info.id,
        quote_date: new Date().toISOString().split('T')[0]
      });
    }
    // Initialize all textareas
    // M.textareaAutoResize(document.querySelector('.materialize-textarea'));
  }

  componentDidUpdate(prevProps) {
    // Update textarea height when quote body changes
    if (prevProps.quote?.body !== this.props.quote?.body) {
      M.textareaAutoResize(document.querySelector('.materialize-textarea'));
    }
  }

  setTempInput(fieldName, patch) {
    // clear temp input for a field
    let tempInput = {...this.state.tempInput};

    if (!tempInput[fieldName]) {
      return;
    }

    if (patch) {
      tempInput[fieldName] = {...tempInput[fieldName], ...patch};
    } else {
      delete tempInput[fieldName];
    }
    return this.setState({tempInput});
  }

  handleInputBlur(field, line) {
    let fieldName = line ? `quote_lines.${line.id}.${field}` : field;
    let tempInput = this.state.tempInput[fieldName];
    let storedValue = line ? this.props.quote.quote_lines[line.id][field] : this.props.quote[field];

    if (`${tempInput?.value}` === `${storedValue}`) { // if temp input value matches stored value, clear temp input
      this.setTempInput(fieldName, null);
    } else { // if temp input value doesn't match stored value, update stored value
      this.setTempInput(fieldName, {status: 'saving'});
      this.props.updateQuote(
        this.props.authToken, 
        {id: this.props.quote.id, [fieldName]: tempInput.value}, 
        () => this.setTempInput(fieldName, null),
        () => this.setState({errorFields: [...this.state.errorFields, fieldName]})
      );
    }
  }

  searchResults(value) { // update eventually to use fuse
    return this.props.quotableProducts.filter((product) => 
      product.model.toLowerCase().includes(value.toLowerCase()) ||
      product.description.toLowerCase().includes(value.toLowerCase())
    ).slice(0, 10);
  }

  quotableProductPatch(product) { // fired when user clicks a suggested product
    return {
      quotable_product_id: product.id,
      model: product.model,
      description: product.description,
      price: product.cost,
    }
  }

  addQuotableProduct(line, product) {
    let linePatch = {
      quotable_product_id: product.id,
      model: product.model,
      description: product.description,
      price: product.cost,
    }

    let quotePatch = {
      id: this.props.quote.id,
      quote_lines_attributes: {[line.id]: linePatch}
    }

    this.props.updateQuote(this.props.authToken, quotePatch, () => {
      this.setTempInput(`quote_lines.${line.id}.model`, null);
    });
  }

  formatPrice(price) {
    return `$${parseFloat(price || 0).toFixed(2)}`;
  }

  handleInputFocus(field, line) {
    let tempInput = {...this.state.tempInput};
    let fieldName = line ? `quote_lines.${line.id}.${field}` : field;
    let value = line ? this.props.quote.quote_lines[line.id][field] : this.props.quote[field];

    tempInput[fieldName] = {value: value, status: 'editing'};
    this.setState({tempInput: tempInput});
  }

  handleInputChange(field, value, line) {
    let tempInput = {...this.state.tempInput};
    let fieldName = line ? `quote_lines.${line.id}.${field}` : field;
    tempInput[fieldName] = {value: value, status: 'editing'};
    this.setState({tempInput: tempInput});
  }

  handleAdditionalServicesSubmit(fieldName) {
    let tempValue = this.state.tempInput[fieldName]?.value;

    if (!tempValue) {
      return;
    }

    let storedValues = this.props.quote[fieldName] ? this.props.quote[fieldName].split(',') : [];
    
    let concatValues = new Set([...storedValues, tempValue]);
    let joinedValue = Array.from(concatValues).filter(Boolean).join(',') + ',';
    let quotePatch = {id: this.props.quote.id, [fieldName]: joinedValue};

    this.props.updateQuote(
      this.props.authToken, 
      quotePatch, 
      () => this.setTempInput(fieldName, null),
      () => this.setState({errorFields: [...this.state.errorFields, fieldName]})
    );
  }

  removeAdditionalService(service, fieldName) {
    let storedValues = this.props.quote[fieldName] ? this.props.quote[fieldName].split(',') : [];
    let filteredValues = storedValues.filter(value => value !== service);
    let joinedValue = filteredValues.filter(Boolean).join(',') + ',';
    let quotePatch = {id: this.props.quote.id, [fieldName]: joinedValue};
    this.props.updateQuote(this.props.authToken, quotePatch);
  }

  formatLeadTime() {
    let tempLeadTime = this.getFieldValue('lead_time');
    return tempLeadTime ? `Lead time: ${tempLeadTime}` : '';
  }

  getFieldStatus(field, line) {
    let fieldName = line ? `quote_lines.${line.id}.${field}` : field;
    return this.state.tempInput[fieldName]?.status || 'saved';
  }

  getFieldValue(field, line) {
    let fieldName = line ? `quote_lines.${line.id}.${field}` : field;
    let savedValue = line ? this.props.quote.quote_lines[line.id][field] : this.props.quote[fieldName];
    let tempInput = this.state.tempInput[fieldName];
    return tempInput?.value || savedValue || '';
  }

  handleRemoveLine(line) {
    let quotePatch = {id: this.props.quote.id, quote_lines_attributes: {[line.id]: {'_destroy': true}}};
    
    this.props.updateQuote(this.props.authToken, quotePatch);

    // clear temp input for line
    Object.keys(line).forEach(key => {
      this.setTempInput(`quote_lines.${line.id}.${key}`, null);
    });
  }

  handleRecipientSelect(email) {
    let recipients = this.state.tempInput['recipients']?.value ? this.state.tempInput['recipients']?.value.split(',') : [];

    // add or remove recipient
    if (recipients.includes(email)) {
      recipients = recipients.filter(r => r !== email);
    } else {
      recipients.push(email);
    }

    // join recipients and update quote
    let joinedRecipients = recipients.filter(Boolean).join(',');
    return this.setTempInput('recipients', {value: joinedRecipients, status: 'editing'});
  }

  handleRecipientSubmit() {
    let recipients = this.state.tempInput['recipients']?.value || '';
    if (recipients === this.props.quote.recipients) {
      this.setTempInput('recipients', null);
      return;
    } else {
      this.props.updateQuote(
        this.props.authToken, 
        {id: this.props.quote.id, recipients}, // cool trick, effectively {...recipients: recipients}
        () => {
          this.setTempInput('recipients', null);
          this.setState({tempEmail: ''});
        },
        () => this.setState({errorFields: [...this.state.errorFields, 'recipients']})
      );
    }
  }

  validateEmail(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  addNewAccountContact(email) {
    let patch = {
      name: '',
      phone: null,
      title: null,
      primary: false,
      reports: false,
      email: email,
      new: true,
      customer: this.props.info.id,
      id: Date.now()
    }

    return new Promise((resolve) => {
      this.props.freezeInfoUpdate('contact', patch, this.props.authToken, () => {
        let infoCopy = {...this.props.info};
        infoCopy.contacts = [...infoCopy.contacts, patch];
        this.props.updateLookupInfo(infoCopy)
          .then(() => this.handleRecipientSelect(email))
          .then(() => this.setState({tempEmail: ''}))
          .then(() => resolve());
      });
    });
  }

  handleDeleteQuote(e) {
    e.stopPropagation();
    if (this.state.confirmDelete) {
      this.props.deleteQuote(this.props.authToken, this.props.quote.id);
    } else {
      this.setState({confirmDelete: true});

      setTimeout(() => {
        this.setState({confirmDelete: false});
      }, 3000);
    }
  }

  render() {
    if (!this.props.quotableProducts || !this.props.quote) {
      return <div className="modal-backdrop" onClick={() => this.props.close()}>
        <div className="modal">
          <div className="modal-content">
            <h3>Preparing Quote Wizard...</h3>
          </div>
        </div>
      </div>
    }

    return (
      <div className="modal-backdrop modal-large" onClick={() => this.props.close()}>
        <button className="modal-close" onClick={() => this.props.close()}>Close</button>
        {this.props.quote.sent_at ? null : (
          <button className="quote-delete-button" onClick={(e) => this.handleDeleteQuote(e)}>
            {this.state.confirmDelete ? 'Are you sure?' : 'Delete Draft'}
          </button>
        )}
      
        <div className="modal" onClick={e => e.stopPropagation()}>
          <div className="modal-content lightgrey-background" style={{padding: 0}}>
            <div className="quote-wizard">
              <div className="quote-wizard-watermark"></div>
              <div className="quote-wizard-header">
                <p>{new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</p>
                <span>{this.props.info.name}</span><br></br>
                <span>{this.props.info.addresses[0].street}</span><br></br>
                <span>{this.props.info.addresses[0].city}, {this.props.info.addresses[0].state} {this.props.info.addresses[0].zip}</span><br></br>
                <p 
                  className="quote-wizard-header-recipients"
                  onClick={() => this.handleInputFocus('recipients')}
                >
                  To: {this.props.quote.recipients?.split(',').join(', ')}
                </p>
              </div>


              {/* debug */}
              {/* <span style={{color: 'red'}}>{JSON.stringify(this.state.tempInput)}</span><br></br> */}

              <div className="quote-wizard-body">
                {/* <input type="text" placeholder="Subject (Optional)" /> */}
                <form className="quote-form" onSubmit={(e) => {e.preventDefault()}}>
                  <div className="quote-form-row">
                    <input type="text" placeholder="Subject (Optional)" 
                      className={`quote-form-field subject-field ${this.getFieldStatus('subject')}`}
                      onFocus={() => this.handleInputFocus('subject')}
                      onInput={(e) => {
                        e.preventDefault();
                        this.handleInputChange('subject', e.target.value)
                      }}
                      value={this.getFieldValue('subject')}
                      onBlur={(e) => {
                        e.preventDefault();
                        this.handleInputBlur('subject');
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          this.handleInputBlur('subject');
                        }
                      }}
                    />
                  </div>

                  <div className="quote-form-row">
                    <textarea 
                      placeholder="Message"
                      className={`quote-form-field body-field materialize-textarea ${this.getFieldStatus('body')}`}
                      type="text"
                      onFocus={() => this.handleInputFocus('body')}
                      onInput={(e) => {
                        e.preventDefault();
                        this.handleInputChange('body', e.target.value)
                      }}
                      value={this.getFieldValue('body')}
                      onBlur={(e) => {
                        e.preventDefault();
                        this.handleInputBlur('body');
                      }}
                    />
                  </div>

                  {Object.values(this.props.quote.quote_lines)
                    .sort((a, b) => {
                      if (a.id === 0) return 1;
                      if (b.id === 0) return -1;
                      return 0;
                    })
                    .map((line) => (
                    <div className="quote-form-line">
                      <div className="quote-form-row">
                        <input
                          placeholder="Search for a Product"
                          className={`quote-form-field product-field ${this.getFieldStatus('model', line)}`} 
                          style={{flex: 2}} type="text" 
                          value={this.getFieldValue('model', line)}
                          onFocus={() => this.handleInputFocus('model', line)}
                          onInput={(e) => {
                            e.preventDefault();
                            this.handleInputChange('model', e.target.value, line)
                          }}
                          onBlur={(e) => {
                            e.preventDefault();
                            if (!this.state.addProductMode) {
                              this.handleInputBlur('model', line);
                            }
                          }}
                        />

                        {line.model && [
                          <input 
                            placeholder="Description" 
                            className={`quote-form-field product-field ${this.getFieldStatus('description', line)}`} 
                            style={{flex: 4}} type="text"
                            value={this.getFieldValue('description', line)}
                            onFocus={() => this.handleInputFocus('description', line)}
                            onInput={(e) => {
                              e.preventDefault();
                              this.handleInputChange('description', e.target.value, line)
                            }}
                            onBlur={(e) => {
                              e.preventDefault();
                              this.handleInputBlur('description', line);
                            }}
                          />,
                          <input 
                            placeholder="Qty" 
                            className={`quote-form-field product-field centered-text ${this.getFieldStatus('quantity', line)}`} 
                            style={{flex: 1}} type="text"
                            onFocus={() => this.handleInputFocus('quantity', line)}
                            onInput={(e) => {
                              e.preventDefault();
                              this.handleInputChange('quantity', e.target.value, line)
                            }}
                            value={this.getFieldValue('quantity', line)}
                            onBlur={(e) => {
                              e.preventDefault();
                              this.handleInputBlur('quantity', line);
                            }}
                          />,
                          <input 
                            placeholder="Price" 
                            className={`quote-form-field product-field centered-text ${this.getFieldStatus('price', line)}`} 
                            style={{flex: 2}} type="text" 
                            onFocus={() => this.handleInputFocus('price', line)}
                            onInput={(e) => {
                              e.preventDefault();
                              this.handleInputChange('price', e.target.value, line)
                            }}
                            value={this.getFieldValue('price', line)}
                            onBlur={(e) => {
                              e.preventDefault();
                              this.handleInputBlur('price', line);
                            }}
                          />
                        ]}
                        {line.id == 0 ? null : (
                          <button 
                            className="quote-form-button remove-product-button"
                            onClick={() => this.handleRemoveLine(line)}
                          >X</button>
                        )}
                      </div>

                      {/* search suggestions */}
                      {this.state.tempInput[`quote_lines.${line.id}.model`]?.value && (
                        <div className="quote-form-row">
                          <div className="product-suggestions-container">
                            {this.searchResults(this.state.tempInput[`quote_lines.${line.id}.model`]?.value).map(product => (
                              <button
                                className="product-suggestion" 
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.addQuotableProduct(line, product);
                                }}
                                onMouseEnter={() => this.setState({addProductMode: true})}
                                onMouseLeave={() => this.setState({addProductMode: false})}
                              >
                              <span className="product-suggestion-model">{product.model}</span>
                              <span className="product-suggestion-description">{product.description}</span>
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                    </div>
                  ))}

                  {/* <div className="quote-form-row">
                    <button 
                      className="quote-form-button add-product-button"
                      onClick={() => this.newQuoteLine()}
                    >
                      Add Another Product to Quote
                    </button>
                  </div> */}

                  <div className="quote-form-row" style={{marginTop: '30px'}}>
                    <input 
                      placeholder="Additional Services Included" 
                      className={`quote-form-field ${this.getFieldStatus('additional_services_included')}`} 
                      type="text" 
                      style={{flex: 1}}
                      onInput={(e) => {
                        e.preventDefault();
                        this.handleInputChange('additional_services_included', e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ',') {
                          e.preventDefault();
                          this.handleAdditionalServicesSubmit('additional_services_included');
                        }
                      }}
                      onBlur={(e) => {
                        e.preventDefault();
                        this.handleAdditionalServicesSubmit('additional_services_included');
                      }}
                      value={this.state.tempInput['additional_services_included']?.value || ''}
                    />
                    
                    <input 
                      placeholder="Additional Services Excluded" 
                      className={`quote-form-field ${this.getFieldStatus('additional_services_excluded')}`} 
                      type="text" 
                      style={{flex: 1}}
                      onInput={(e) => {
                        e.preventDefault();
                        this.handleInputChange('additional_services_excluded', e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ',') {
                          e.preventDefault();
                          this.handleAdditionalServicesSubmit('additional_services_excluded');
                        }
                      }}
                      onBlur={(e) => {
                        e.preventDefault();
                        this.handleAdditionalServicesSubmit('additional_services_excluded');
                      }}
                      value={this.state.tempInput['additional_services_excluded']?.value || ''}
                    />
                  </div>

                  <div className="quote-form-row">
                    <div className="quote-form-services_container">
                      {this.props.quote['additional_services_included']?.split(',').map((service) => service && (
                        <button 
                          className="quote-form-service"
                          onMouseOver={(e) => e.target.textContent = 'Remove'} 
                          onMouseOut={(e) => e.target.textContent = service}
                          onClick={() => this.removeAdditionalService(service, 'additional_services_included')}
                        >
                          {service}
                        </button>
                      ))}
                    </div>
                    <div className="quote-form-services_container">
                      {this.props.quote['additional_services_excluded']?.split(',').map((service) => service && (
                        <button 
                          className="quote-form-service"
                          onMouseOver={(e) => e.target.textContent = 'Remove'} 
                          onMouseOut={(e) => e.target.textContent = service}
                          onClick={() => this.removeAdditionalService(service, 'additional_services_excluded')}
                        >
                          {service}
                        </button>
                      ))}
                    </div>
                  </div>

                  <div className="quote-form-row">
                    <input 
                      placeholder="Lead Time" 
                      className={`quote-form-field lead-time-field ${this.getFieldStatus('lead_time')}`}
                      type="text"
                      onFocus={() => this.handleInputFocus('lead_time')}
                      onInput={(e) => this.handleInputChange('lead_time', e.target.value.replace(/^Lead time:\s*/i, ''))}
                      value={this.formatLeadTime()}
                      onBlur={(e) => {
                        e.preventDefault();
                        this.handleInputBlur('lead_time');
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          this.handleInputBlur('lead_time');
                        }
                      }}
                    />
                  </div>
                </form>
              </div>
              <div className="quote-wizard-footer">
                <i>ProAsys • 318 Hendel St • Shillington, PA 19607-2495 • ph: 610-775-1505 • fax: 610-775-8776</i><br></br>
                <i>proasysinc.com</i>
              </div>
            </div>

            <div className="quote-wizard-buttons-container">
              <a href={`https://proasysinc.com/quotes/show?id=${this.props.quote.hash_id}`} target="_blank" className="quote-wizard-button preview-button">
                Preview Quote
              </a>    

              <button 
                className="quote-wizard-button select-recipients-button" 
                onClick={() => {
                  this.handleInputFocus('recipients');
                }}
              >
                Select Recipients
              </button>
            
              <button className={`quote-wizard-button send-button ${this.props.quote.recipients?.length ? '' : 'disabled'}`}>
                Send Quote
              </button>
            </div>
          </div>
        </div>

        {this.state.tempInput?.['recipients'] && (
          <div className="modal-backdrop" onClick={(e) => {
            e.stopPropagation();
            if (this.validateEmail(this.state.tempEmail)) {
              this.addNewAccountContact(this.state.tempEmail)
              .then(() => this.handleRecipientSubmit());
            } else {
              this.setState({tempEmail: ''});
              this.handleRecipientSubmit();
            }
          }}>
            <div className="modal" style={{width: '500px'}} onClick={(e) => e.stopPropagation()}>
              <div className="modal-content recipient-select-modal-content">
                <h4>Select Recipients</h4>
                {this.props.info.contacts.map(contact => (
                  <div className="recipient-select-row" onClick={() => this.handleRecipientSelect(contact.email)}>
                    <div className="recipient-select-row-checkbox">
                      <Checkbox 
                        checked={this.state.tempInput['recipients']?.value?.includes(contact.email)}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </div>
                    <div className="recipient-select-row-info">
                      <span><b>{contact.name}</b></span>
                      <span style={{color: 'grey'}}>{contact.email}</span>
                    </div>
                  </div>
                ))}
                <div className='recipient-select-row'>
                  <div className={`recipient-select-add-icon ${this.validateEmail(this.state.tempEmail) ? '' : 'disabled'}`}>
                    <AddIcon 
                      style={{fontSize: 30}}
                      onClick={() => this.addNewAccountContact(this.state.tempEmail)}
                    />
                  </div>
                  <input
                    type="email"
                    placeholder="contact@example.com"
                    style={{height: '30px'}}
                    onChange={(e) => {
                      this.setState({tempEmail: e.target.value});
                    }}
                    value={this.state.tempEmail}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && this.validateEmail(e.target.value)) {
                        this.handleRecipientSelect(e.target.value);
                        this.setState({tempEmail: ''});
                      }
                    }}
                  />
                </div>
                <div className="recipient-select-row-buttons">
                  <button className="quote-wizard-button btn btn-secondary" onClick={() => this.setTempInput('recipients', null)}>Cancel</button>
                  <button 
                    className="quote-wizard-button btn btn-primary" 
                    onClick={() => {
                      if (this.validateEmail(this.state.tempEmail)) {
                        this.addNewAccountContact(this.state.tempEmail)
                        .then(() => this.handleRecipientSubmit());
                      } else {
                        this.setState({tempEmail: ''});
                        this.handleRecipientSubmit();
                      }
                    }}
                  >{this.props.loading ? this.props.loadingMessage : 'Continue'}</button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    authToken: state.authToken,
    info: state.info,
    quote: state.activeQuote,
    quotableProducts: state.quotableProducts,
    loading: state.loading,
    loadingMessage: state.loadingMessage
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(QuoteGeneratorModal);
